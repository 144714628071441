<template>
  <v-card flat>
    <v-toolbar color="primary" dense dark flat>
      <span>{{ reactivo ? "Modificar reactivo" : "Nuevo reactivo" }}</span>
    </v-toolbar>
    <v-card-text class="py-2">
      <v-row dense class="pt-2">
        <v-col cols="6">
          <v-select
            v-model="tipo"
            :items="tipos"
            item-text="texto"
            item-value="valor"
            dense
            outlined
            label="Tipo de reactivo"
          ></v-select>
        </v-col>
        <v-col v-if="tipo === 'opciones'" cols="6">
          <v-select
            v-model="seleccion"
            :items="selecciones"
            item-text="texto"
            item-value="valor"
            dense
            outlined
            label="Selección"
          ></v-select>
        </v-col>
      </v-row>
      <div>
        <editor-texto
          contenido="examen"
          :idContenido="$route.params.idExamen"
          :texto="texto"
          ref="editorTexto"
        ></editor-texto>
      </div>
      <div>
        <v-subheader class="px-0"
          >Respuestas {{ tipo === "opciones" ? "" : "correctas" }}:</v-subheader
        >
        <draggable
          v-model="respuestas"
          tag="div"
          handle=".handle"
          @change="modificarIndexRespuesta"
        >
          <v-list-item v-for="(respuesta, index) in respuestas" :key="index">
            <v-list-item-icon>
              <v-btn icon style="cursor: move" class="handle"
                ><v-icon>mdi-dots-grid</v-icon></v-btn
              >
              <v-switch
                v-model="respuesta.correcta"
                :readonly="tipo === 'abierta'"
                color="success"
                class="ma-0 mt-1"
                dense
                append-icon="mdi-check"
              ></v-switch>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-text-field
                  v-model="respuesta.texto"
                  dense
                  :placeholder="`Respuesta ${index + 1} - ${
                    (respuesta.correcta && 'Correcta') || 'Incorrecta'
                  }`"
                  :rules="[
                    (v) => !!v || 'El texto de la respuesta es requerido.',
                  ]"
                >
                </v-text-field>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="eliminarRespuesta(index)">
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </draggable>
        <v-container class="px-3 py-0">
          <v-text-field
            v-model="nuevaRespuesta"
            label="Nueva respuesta"
            filled
            dense
            @keyup.enter="agregarRespuesta()"
          >
            <template #append>
              <v-btn
                :disabled="!nuevaRespuesta"
                color="secondary"
                elevation="0"
                small
                @click="agregarRespuesta()"
              >
                <v-icon small class="mr-2">mdi-plus</v-icon>
                <span>Agregar</span>
              </v-btn>
            </template>
          </v-text-field>
        </v-container>
      </div>
      <div class="pb-3" :class="{ 'py-3': tipo === 'abierta' }">
        <v-btn
          :disabled="loading"
          :loading="loading"
          small
          color="primary"
          @click="guardarReactivo()"
        >
          <v-icon small class="mr-2">mdi-content-save</v-icon>
          <span>Guardar reactivo</span>
        </v-btn>
        <v-btn
          v-if="reactivo"
          color="secondary"
          outlined
          small
          :disabled="loading"
          class="ml-2"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";

import {
  crearReactivo,
  modificarReactivo,
  crearRespuesta,
  modificarRespuesta,
  eliminarRespuesta,
} from "./examenReactivos.service";

import editorTexto from "../../contenidos/editorTexto.vue";

export default {
  props: {
    idPagina: { type: String, required: true },
    reactivo: { type: Object, default: () => null },
  },

  components: {
    "editor-texto": editorTexto,
    draggable,
  },

  data() {
    return {
      loading: false,
      texto: "",
      reactivoLocal: JSON.parse(JSON.stringify(this.reactivo)),
      respuestas: [
        {
          texto: "Respuesta 1",
          correcta: false,
        },
        {
          texto: "Respuesta 2",
          correcta: false,
        },
      ],
      tipos: [
        {
          texto: "Reactivo de opciones",
          valor: "opciones",
        },
        {
          texto: "Reactivo abierto",
          valor: "abierta",
        },
      ],
      tipo: "opciones",
      selecciones: [
        {
          texto: "Selección única",
          valor: "unica",
        },
        {
          texto: "Selección multiple",
          valor: "multiple",
        },
      ],
      seleccion: "unica",
      nuevaRespuesta: "",
    };
  },

  watch: {
    tipo() {
      if (!this.reactivoLocal) this.resetRespuestas();
      if (this.tipo === "abierta") this.todasLasRespuestasCorrectas();
    },

    respuestas() {
      if (this.tipo === "abierta") this.todasLasRespuestasCorrectas();
    },
  },

  mounted() {
    if (this.reactivo) this.setCurrentData();
  },

  methods: {
    setCurrentData() {
      this.texto = this.reactivoLocal.texto;
      this.respuestas = this.reactivo.respuestas;
      this.tipo = this.reactivoLocal.tipo;
      this.seleccion = this.reactivoLocal.seleccion;
    },

    validarTextoReactivo() {
      const texto = this.$refs.editorTexto.obtenerTexto();

      if (!texto || texto === "<p></p>") {
        this.$systemErrorMessage("El texto del reactivo es requerido.");
        return {};
      }
      return { ok: true };
    },

    validarRespuestas() {
      const respuestasValidas = this.respuestas.filter((e) => e.texto);
      if (respuestasValidas.length !== this.respuestas.length) {
        this.$systemErrorMessage("Las respuestas vacías no son válidas.");
        return {};
      }
      return { ok: true };
    },

    async agregarRespuesta() {
      if (!this.nuevaRespuesta) {
        this.$systemErrorMessage("Se necesita una respuesta para agregar.");
        return;
      }
      if (!this.validarRespuestas().ok) return;

      if (this.reactivoLocal) await this.agregarRespuestaRequest();
      else
        this.respuestas.push({ correcta: false, texto: this.nuevaRespuesta });
      this.nuevaRespuesta = "";
    },

    async agregarRespuestaRequest() {
      this.loading = true;

      try {
        const data = {
          texto: this.nuevaRespuesta,
          correcta: this.tipo === 'abierta',
          reactivo: this.reactivoLocal._id,
        };
        const serverResponse = await crearRespuesta({
          idExamen: this.$route.params.idExamen,
          data,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.respuestas.push(serverResponse.respuesta);
          this.$emit("reactivoCreado", { respuestas: this.respuestas }, false);
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async eliminarRespuesta(indexAEliminar) {
      if (this.respuestas.length <= 2) {
        this.$systemErrorMessage("No se pueden tener menos de 2 respuestas.");
        return;
      }

      if (this.reactivoLocal) {
        const idRespuesta = this.respuestas[indexAEliminar]._id;
        await this.eliminarRespuestaRequest(idRespuesta);
      }

      this.respuestas = this.respuestas.filter(
        (e, index) => index !== indexAEliminar
      );
    },

    async eliminarRespuestaRequest(idRespuesta) {
      this.loading = true;

      try {
        const serverResponse = await eliminarRespuesta({
          idExamen: this.$route.params.idExamen,
          idRespuesta,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async guardarReactivo() {
      if (!this.validarTextoReactivo().ok || !this.validarRespuestas().ok)
        return;
      this.loading = true;

      try {
        const { idExamen } = this.$route.params;
        const data = {
          texto: this.$refs.editorTexto.obtenerTexto(),
          tipo: this.tipo,
          seleccion: this.seleccion,
          examen: idExamen,
          pagina: this.idPagina,
          ...(!this.reactivo && { respuestas: this.respuestas }),
        };

        if (this.reactivoLocal) await this.verificarModificacionRespuestas();

        const serverResponse = this.reactivo
          ? await modificarReactivo({
              idExamen,
              idReactivo: this.reactivoLocal._id,
              data,
            })
          : await crearReactivo({
              idExamen,
              data,
            });

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.$emit("reactivoCreado", serverResponse.reactivo);
          this.resetForm();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async verificarModificacionRespuestas() {
      const respuestaExiste = (idRespuesta) =>
        this.reactivoLocal.respuestas.find((e) => e._id == idRespuesta);

      const respuestaEsActualizada = (idRespuesta) => {
        const respuestaAnterior = this.reactivoLocal.respuestas.find(
          (e) => e._id == idRespuesta
        );
        const respuestaActual = this.respuestas.find(
          (e) => e._id == idRespuesta
        );

        return (
          respuestaActual.correcta != respuestaAnterior.correcta ||
          respuestaActual.texto != respuestaAnterior.texto
        );
      };

      for (let index = 0; index < this.respuestas.length; index++) {
        let respuesta = this.respuestas[index];
        if (
          respuestaExiste(respuesta._id) &&
          respuestaEsActualizada(respuesta._id)
        ) {
          await this.modificarRespuesta(respuesta._id, {
            texto: respuesta.texto,
            correcta: respuesta.correcta,
          });
        }
      }
    },

    async modificarRespuesta(idRespuesta, data) {
      try {
        const serverResponse = await modificarRespuesta({
          idExamen: this.$route.params.idExamen,
          idRespuesta,
          data,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async modificarIndexRespuesta(event) {
      if (!this.reactivoLocal) return;
      this.loading = true;

      const idRespuesta = event.moved.element._id;
      const nuevoIndex = event.moved.newIndex;

      await this.modificarRespuesta(idRespuesta, { nuevoIndex });
      this.$emit("reactivoCreado", { respuestas: this.respuestas }, false);
    },

    resetForm() {
      this.$refs.editorTexto.resetearTexto();
      this.resetRespuestas();
    },

    resetRespuestas() {
      this.respuestas = [
        {
          correcta: false,
          texto: "Respuesta 1",
        },
        {
          correcta: false,
          texto: "Respuesta 2",
        },
      ];
      this.nuevaRespuesta = "";
    },

    todasLasRespuestasCorrectas() {
      this.respuestas.forEach((e) => (e.correcta = true));
    },
  },
};
</script>
