<template>
  <div>
    <div>
      <v-tabs v-model="tab" vertical>
        <div v-if="permisoEscritura" class="plus-button">
          <v-btn
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="mostrarCreacionPagina = true"
            block
            small
            class="mb-2"
          >
            <v-icon small class="mr-2">mdi-plus</v-icon>
            <span>Nueva página</span>
          </v-btn>
        </div>
        <draggable
          v-model="paginasLocal"
          tag="div"
          handle=".handle"
          @change="modificarIndexPagina"
        >
          <v-list-item
            v-for="(pagina, index) in paginasLocal"
            :key="pagina._id"
            :disabled="loading"
            :class="{ 'pagina-activa': tab == pagina._id }"
            @mouseenter="indexPaginaHover = index"
            @mouseleave="indexPaginaHover = null"
          >
            <v-list-item-avatar
              v-if="permisoEscritura"
              class="mr-0"
              :class="{ handle: !loading }"
            >
              <v-btn
                small
                icon
                style="cursor: move"
                class="handle mr-2"
                :disabled="loading"
                ><v-icon small>mdi-dots-grid</v-icon></v-btn
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <a class="text-decoration-none" @click="tab = pagina._id">{{
                  pagina.nombre
                }}</a>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span>{{ pagina.tipo }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="index === indexPaginaHover && permisoEscritura"
                icon
                color="primary"
                small
                class="ml-2"
                @click="modificarPagina(index)"
                ><v-icon small>mdi-pencil</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </draggable>
        <v-tabs-items v-model="tab" class="pl-2">
          <v-tab-item
            v-for="pagina in paginasLocal"
            :key="pagina._id"
            :value="pagina._id"
          >
            <examen-pagina
              :pagina="pagina"
              :permisoEscritura="permisoEscritura"
            ></examen-pagina>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <pagina-dialog
      v-if="mostrarCreacionPagina"
      :mostrar="mostrarCreacionPagina"
      :pagina="paginaParaModificar"
      @cancelar="cerrarPaginaDialog()"
      @paginaGuardada="paginaGuardada"
    ></pagina-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import examenPagina from "./examenPagina.vue";

import { modificarPagina } from "./examenes.service";

export default {
  props: {
    paginas: { type: Array, default: () => [] },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    draggable,
    "pagina-dialog": () => import("./crearPaginaExamen.vue"),
    "examen-pagina": examenPagina,
  },

  data() {
    return {
      loading: false,
      paginasLocal: this.paginas,
      tab: 0,
      indexPaginaHover: null,
      mostrarCreacionPagina: false,
      paginaParaModificar: null,
    };
  },

  watch: {
    paginas(value) {
      this.paginasLocal = value;
    },
  },

  mounted() {
    if (this.paginasLocal.length) this.tab = this.paginasLocal[0]._id;
  },

  methods: {
    paginaGuardada(pagina) {
      this.$emit("paginaGuardada", pagina);
      const indexPagina = this.paginasLocal.findIndex(
        (e) => e._id === pagina._id
      );
      const paginaExiste = indexPagina !== -1;
      if (paginaExiste) this.paginasLocal[indexPagina] = pagina;
      else {
        this.paginasLocal.push(pagina);
        this.tab = pagina._id;
      }
      this.cerrarPaginaDialog();
    },

    cerrarPaginaDialog() {
      this.paginaParaModificar = null;
      this.mostrarCreacionPagina = false;
    },

    modificarPagina(index) {
      this.paginaParaModificar = this.paginasLocal[index];
      this.mostrarCreacionPagina = true;
    },

    async modificarIndexPagina(event) {
      this.loading = true;

      try {
        const idPagina = event.moved.element._id;
        const nuevoIndex = event.moved.newIndex;

        const data = { nuevoIndex };
        const serverResponse = await modificarPagina({
          idPagina,
          idExamen: this.$route.params.idExamen,
          data,
        });

        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.tab = idPagina;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagina-activa {
  background-color: #eceff1;
}
</style>
