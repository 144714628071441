<template>
  <v-card color="#eceff1" flat>
    <v-card-text>
      <div v-if="paginaLocal.tipo === 'lectura'">
        <v-toolbar dense color="primary" dark flat shaped class="mb-3">
          <v-toolbar-title>Lectura</v-toolbar-title>
        </v-toolbar>
        <div
          v-html="paginaLocal.lectura"
          class="ck-content mb-3"
          style="overflow: hidden"
        ></div>
      </div>
      <div>
        <v-toolbar dense color="secondary" dark flat shaped class="mb-3">
          <v-toolbar-title>Reactivos</v-toolbar-title>
        </v-toolbar>
        <loading-reactivos v-if="loading"></loading-reactivos>
        <span v-else-if="!reactivos.length">
          No se han agregado reactivos.
        </span>
        <div v-else>
          <reactivo-examen
            v-for="(reactivo, index) in reactivos"
            :key="reactivo._id"
            :reactivo="reactivo"
            :index="index"
            :permisoEscritura="permisoEscritura"
            class="my-3"
          ></reactivo-examen>
        </div>
        <div v-if="!loading && permisoEscritura">
          <crear-reactivo
            :idPagina="paginaLocal._id"
            class="my-3"
            @reactivoCreado="reactivoCreado"
          ></crear-reactivo>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import loadingReactivos from "./reactivos/loadingReactivos.vue";
import crearReactivo from "./reactivos/crearReactivoExamen.vue";
import { getPagina } from "./examenes.service";

export default {
  props: {
    pagina: { type: Object, required: true },
    permisoEscritura: { type: Boolean, default: false },
  },

  components: {
    "loading-reactivos": loadingReactivos,
    "reactivo-examen": () => import("./reactivos/examenReactivo.vue"),
    "crear-reactivo": crearReactivo,
  },

  data() {
    return {
      paginaLocal: this.pagina,
      reactivos: [],
      loading: true,
    };
  },

  watch: {
    pagina(value) {
      this.paginaLocal = value;
    },
  },

  mounted() {
    this.getPagina();
  },

  methods: {
    async getPagina() {
      this.loading = true;

      try {
        const { idExamen } = this.$route.params;
        const serverResponse = await getPagina({
          idExamen,
          idPagina: this.paginaLocal._id,
        });

        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.reactivos = serverResponse.pagina.reactivos;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    reactivoCreado(reactivo) {
      this.reactivos.push(reactivo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../classes/ck-content.css";
</style>
