<template>
  <div>
    <v-container :class="{ 'pt-5': app !== 'campus' }">
      <v-skeleton-loader
        v-if="!escuelaTienePermiso"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-card v-else rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="$router.push(`/examenes/${seccion.examen._id}`)"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <v-spacer></v-spacer> {{ seccion ? seccion.examen.nombre : "" }}
          <v-spacer></v-spacer>
          <v-menu v-if="permisoEscritura" offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menuSeccionItem, index) in menuSeccion"
                :key="index"
                @click="menuAction(menuSeccionItem)"
              >
                <v-list-item-title>
                  <v-icon small class="mr-2">{{ menuSeccionItem.icon }}</v-icon>
                  <span>{{ menuSeccionItem.text }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-container v-if="loading || !seccion">
          <loading-seccion></loading-seccion>
        </v-container>
        <v-container v-else>
          <v-card-title class="py-1 px-3">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!prevSeccion"
                  @click="
                    $router.push(
                      `/examenes/${seccion.examen._id}/seccion/${prevSeccion}`
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
              </template>
              <span>Sección anterior</span>
            </v-tooltip>
            <v-spacer></v-spacer> {{ seccion.nombre }} <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  :disabled="!nextSeccion"
                  @click="
                    $router.push(
                      `/examenes/${seccion.examen._id}/seccion/${nextSeccion}`
                    )
                  "
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </template>
              <span>Sección siguiente</span>
            </v-tooltip>
          </v-card-title>
          <v-divider class="mx-3 my-3"></v-divider>
          <v-card-text class="pa-0 text--secondary">
            <v-container>
              <span
                ><strong>Tiempo de duración:</strong>
                {{ seccion.tiempo }} minutos</span
              >
            </v-container>
            <v-container>
              <div
                v-if="seccion.descripcion && seccion.descripcion !== '<p></p>'"
                v-html="seccion.descripcion"
                class="ck-content mt-2"
                style="overflow: hidden"
              ></div>
              <div v-else>
                <span>No se ha capturado descripción.</span>
              </div>
            </v-container>
            <v-container>
              <examen-paginas
                :paginas="seccion.paginas"
                :permisoEscritura="permisoEscritura"
                @paginaGuardada="paginaGuardada"
              ></examen-paginas>
            </v-container>
          </v-card-text>
        </v-container>
      </v-card>
    </v-container>

    <modificar-seccion
      v-if="mostrarModificarSeccion"
      :mostrar="mostrarModificarSeccion"
      :seccion="seccion"
      @cancelar="mostrarModificarSeccion = false"
      @seccionGuardada="seccionGuardada"
    ></modificar-seccion>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSeccion } from "./examenes.service";

import loadingSeccion from "./loadingSeccionExamen.vue";
import examenPaginas from "./examenPaginas.vue";
import { permisoMenuEscuela } from "../../helpers/utilerias";

export default {
  components: {
    "loading-seccion": loadingSeccion,
    "modificar-seccion": () => import("./crearSeccionExamenDialog.vue"),
    "examen-paginas": examenPaginas,
  },

  data: () => ({
    loading: true,
    seccion: null,
    permisoEscritura: false,
    menuSeccion: [
      {
        text: "Modificar sección",
        action: "modificarSeccion",
        icon: "mdi-square-edit-outline",
      },
    ],
    mostrarModificarSeccion: false,
    escuelaTienePermiso: false,
  }),

  computed: {
    ...mapGetters(["sessionToken", "app", "menu"]),

    prevSeccion() {
      if (this.seccion) {
        const { examen } = this.seccion;
        const indexAnterior = examen.secciones.indexOf(this.seccion._id) - 1;
        if (examen.secciones[indexAnterior])
          return examen.secciones[indexAnterior];
        else return null;
      }

      return null;
    },

    nextSeccion() {
      if (this.seccion) {
        const { examen } = this.seccion;
        const indexSiguiente = examen.secciones.indexOf(this.seccion._id) + 1;
        if (examen.secciones[indexSiguiente])
          return examen.secciones[indexSiguiente];
        else return null;
      }

      return null;
    },
  },

  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getSeccion();
        }
      }
    },

    $route() {
      if (this.seccion && this.$route.params.idSeccion !== this.seccion._id) {
        if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getSeccion();
        }
      }
    },
  },

  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Exámenes")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.getSeccion();
      }
    }
  },

  methods: {
    menuAction(data) {
      switch (data.action) {
        case "modificarSeccion":
          this.mostrarModificarSeccion = true;
          break;
      }
    },

    async getSeccion() {
      this.loading = true;

      try {
        const { idSeccion, idExamen } = this.$route.params;

        const serverResponse = await getSeccion(idExamen, idSeccion);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/examenes");
        } else {
          this.seccion = serverResponse.seccion;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    seccionGuardada({ nombre, descripcion, tiempo }) {
      this.seccion.nombre = nombre;
      this.seccion.descripcion = descripcion;
      this.seccion.tiempo = tiempo;

      this.mostrarModificarSeccion = false;
    },

    paginaGuardada(pagina) {
      const query = (e) => e._id === pagina._id;
      const indexPagina = this.seccion.paginas.findIndex(query);

      if (indexPagina === -1)
        this.seccion.paginas = [...this.seccion.paginas, pagina];
      else this.seccion.paginas[indexPagina] = pagina;
    },
  },
};
</script>
