import {
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "@/store/store";

const httpURL = store.getters.httpURL;

export const crearReactivo = async ({ idExamen, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/reactivo`;

  return await putServerRequest(url, data, config);
};

export const modificarReactivo = async ({ idExamen, idReactivo, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/reactivo/${idReactivo}`;

  return await patchServerRequest(url, data, config);
};

export const crearRespuesta = async ({ idExamen, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/respuesta`;

  return await putServerRequest(url, data, config);
};

export const modificarRespuesta = async ({ idExamen, idRespuesta, data }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/respuesta/${idRespuesta}`;

  return await patchServerRequest(url, data, config);
};

export const eliminarRespuesta = async ({ idExamen, idRespuesta }) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenes/${idExamen}/respuesta/${idRespuesta}`;

  return await deleteServerRequest(url, config);
};
